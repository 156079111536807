import React, { useState } from 'react';
import Markdown from 'utils/markdownToComponent';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { colors } from 'styles';
import imgModale from 'images/modale/confirmModale.svg';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  opacity: ${props => (props.isLoading ? '1' : '0')};
`;
const StyledMarkdown = styled(Markdown)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;

  h6 {
    color: ${colors.purple};
    font-family: 'Nunito', 'Roboto', Arial, 'Helvetica Neue', Helvetica,
      sans-serif;
    text-transform: uppercase;
    font-size: 20px;
  }
  p {
    font-size: 20px;
  }
`;
const ConfirmModale = props => {
  const [isLoading, setIsLoading] = useState(false);
  setTimeout(() => setIsLoading(true), 400);
  return (
    <>
      <Helmet>
        <style type="text/css">
          {`
          .zsiq_floatmain{
          display: none !important;
          `}
        </style>
      </Helmet>
      <StyledDiv id={'confirmModale'} isLoading={isLoading}>
        <img src={imgModale} alt={'image confirmation'} loading="lazy" />
        <StyledMarkdown>
          {props.data.dataMessage.rawMarkdownBody}
        </StyledMarkdown>
      </StyledDiv>
      <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2126943&vkey=de96b4db594d793c1030373bd93e16b8" />
    </>
  );
};

export default ConfirmModale;
